import { useNavigate } from "react-router-dom";


function About() {
    const navigate = useNavigate()
    return (
        <div className="About">
            <div className="container">
                <h1 className="use-brand-color">About us</h1>
                <p className="mt-2">
                    DKVK JEWELLERS Established in 1995 in AGRA INDIA . 
                    We, Paraswani’s family positions ourself as a family jewellers since 1947 
                    which has been built on the foundation of offering our clients the highest level of 
                    customer service. With over 75 years in the Jewellery trade spanning over 
                    several generations, we are well qualified to take care of every need when it 
                    comes to jewellery. 
                </p>
                <button onClick={() => navigate("/about")} className="btn mt-4"><span>Read More</span></button>
            </div>
        </div>
    )
}

export default About;