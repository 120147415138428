import About from './pages/about/about';
import Contact from './pages/contact/contact';
import Home from './pages/home/home';
import Products from './pages/products/products';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";


function App() {

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Home />,
    },
    {
      path: "/about",
      element: <About />
    },
    {
      path: "/contact",
      element: <Contact />
    },
    {
      path: "/products",
      element: <Products />
    }
  ]);

  
  return (
    <div className="App">
          <RouterProvider router={router} />
    </div>
  );
}

export default App;
