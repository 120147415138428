
import "./footer.scss"


function Footer(){
    return (
    <footer className="Footer">
        <div className="container">
            <div>
                &copy;{new Date().getFullYear()} <span className="font-bauhmas93">Deepak Kumar Vinay Kumar Jewellers</span>
            </div>
            <ul className="social-icons">
              <li>
                <a target="_blank" rel="nofollow noreferrer" className="facebook" href="https://facebook.com/dkvk.jewellers">
                  <i className="fa fa-facebook"></i>
                </a>
              </li>
              <li>
                <a target="_blank" rel="nofollow noreferrer" className="instagram" href="https://instagram.com/dkvk.jewellers">
                  <i className="fa fa-instagram"></i>
                </a>
              </li>
              <li>
                <a target="_blank" rel="nofollow noreferrer" className="email" href="mailto:dkvk.jewellers@gmail.com">
                  <i className="fa fa-envelope"></i>
                </a>
              </li>
              <li>
                <a target="_blank" rel="nofollow noreferrer" className="whatsapp" href="https://wa.me/919837017522?text=Hi,%20got%20your%20Contact%20from%20website!">
                  <i className="fa fa-whatsapp"></i>
                </a>
              </li>
            </ul>
        </div>
    </footer>
    )
}

export default Footer;