import Footer from "../../components/footer/footer";
import Navbar from "../../components/navbar/navbar";
import "./about.scss"

function About(){
    return (
        <>
        <Navbar alwaysCompact={true}/>
        <div className="About">
            <div className="container">
                <h1 className="use-brand-color font-bauhmas93 mt-3">About us</h1>
                <p className="font-artifika mt-1">
                DKVK JEWELLERS Established in 1995 in AGRA INDIA . We, Paraswani’s family positions ourself as a family jewellers since 1947 which has been built on the foundation of offering our clients the highest level of customer service. With over 75 years in the Jewellery trade spanning over several generations, we are well qualified to take care of every need when it comes to jewellery. We not only retail some of the finest designs in contemporary jewellery but as a member of the Guild of Mastercraftsmen, we also offer a bespoke service, giving you the chance to be creative and unique. Having a workshop on site we are well equiped to offer advice and on-the-spot repairs.
When it comes to jewellery you can rely on us! Our team,
All of our staff are friendly and extremely knowledgable. We realise that when it comes to shopping for jewellery it requires trust between the customer and store, being a family run business we like to build relationships with our customers and grow through repeat business.
Not only do all the staff have knowledge of all the stock we carry, but can guide you through a design process to create that perfect piece for you. We also have expert knowledge in diamonds and precious stones available.
                </p>
            </div>
        </div>
                <Footer /> 
                </>

    )
}


export default About;